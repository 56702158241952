footer .footer-bottom {
    width: 100%;
    min-height: 36px;
    background-color: #13AE9C;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 12px 0px 12px 0px !important;
}

.footer-bottom p {
    margin: 0;
}